@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Poppins', sans-serif;
    }
}

@layer utilities {
    .scrollbar-hidden {
        scrollbar-width: none;
    }

    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    .shadow-all {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    }

    @media print {
        .print-fill {
            -webkit-print-color-adjust: exact;
        }

        .print-no-print {
            display: none;
        }

        .print-pagebreak {
            page-break-before: always;
        }

        .break-inside-avoid {
            break-inside: avoid;
        }

        .print-no-padding {
            padding: 0,
        }

        .print-no-margin {
            margin: 0,
        }

        .print-no-border {
            border: 0,
        }
    }
}